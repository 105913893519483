// Skills Icons
import htmlIcon from "./images/html.svg"
import awsIcon from "./images/aws.png"
import reactIcon from "./images/react.svg"
import microsoftIcon from "./images/microsoft.jpg"
import oathIcon from "./images/oath.png"
import googleIcon from "./images/google.png"
import javaIcon from "./images/java.png"
import pythonIcon from "./images/python.png" 
import bashIcon from "./images/bash.png"
import slackIcon from "./images/slack.png"
import basecampIcon from "./images/basecamp.png"
import zoomIcon from "./images/zoom.png"
import trelloIcon from "./images/trello.png"
import meIcon from "./images/me.jpg"

// Social Icon
import githubIcon from "./images/github.svg"
import linkedinIcon from "./images/linkedin.jpg"
import dribbbleIcon from "./images/dribbble.svg"
import instagramIcon from "./images/instagram.svg"

export default {
  //(Please Do Not Remove The comma(,) after every variable)
  //Change The Website Template

  //   Header Details ---------------------
  name: "Julia",
  headerTagline: [
    //Line 1 For Header
    "",
    //Line 2 For Header
    "",
    //Line 3 For Header
    "",
  ],
  //   Header Paragraph
  headerParagraph:
  "Highly motivated, detail-oriented, extraordinarily organized, self-sufficient problem solver",
  //Contact Email
  contactEmail: "julialydick15@gmail.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "PMMC Interactive Globe", //Project Title - Add Your Project Title Here
      para:
        "Worked in a team that delivered an interactive globe as well as an admin interface that collected data from the globe interactions. Directly worked on the back end using python to create GET and POST requests for the globe. I used OAuth to simplify the login for the admin website. I also utilized Docker to build a container structure that housed the back end of our project.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
      "https://srvs.us/julia/1.jpg",
      //Project URL - Add Your Project Url Here
      url: "https://vigilant-bhabha-fc073a.netlify.app",
    },
    {
      title: "UCI Enrollment Redesign", //Project Title - Add Your Project Title Here
      para:
        "Worked in a small team that built a website to display possible redesigns for the UCI class registration system. I initiated team meetings and checkins to maintain deadlines and deliverables using basecamp and google suite. I led a heuristic evaluation of the registration system, compiled other popular registration systems to discern effective usability aspects, and distributed surveys to produce the improved redesign.We built the website to display our redesigns using HTML.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
      "https://srvs.us/julia/2.jpg",
      //Project URL - Add Your Project Url Here
      url: "http://chetanverma.com/",
    },
    {
      title: "Developed API for Bio-Rad", //Project Title - Add Your Project Title Here
      para:
        "Worked in a team in which we successfully created a REST API management prototype using AWS. Our team completed a full agile development lifecycle from setting requirements to iterating over designs and testing throughout our sprints before delivering our proof of concept for Bio-Rad. We utilized pair programming to construct a HTTP gateway with a Lambda integration DynamoDB. I also managed the progress of the project throughout development by working with the sponsor to schedule deadlines and meetings and procure deliverables.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
      "https://srvs.us/julia/3.jpg",
      //Project URL - Add Your Project Url Here
      url: "http://chetanverma.com/",
    },
    {
      title: "Internship at UCSF Psychological Nursing Lab", //Project Title - Add Your Project Title Here
      para:
        "Worked on my own project where I collected data from watches that monitored pulse. I was responsible for recruiting subjects to build the test group, setting up the test criteria, and administering the equipment.  Additionally I ensured the test group properly wore their equipment so accurate data was recorded. I distributed the data from the watches to the manufacturer for further analysis using google suite.", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc:
      "https://srvs.us/julia/4.jpg",
      //Project URL - Add Your Project Url Here
      url: "http://chetanverma.com/",
    },
    

    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "Hi, I'm Julia! I studied informatics, with a specialization in health, at UC Irvine. \
    My coursework covered the fundamentals of informatics, software, and human behavior. \
  ",
  aboutParaTwo:
    "In my senior capstone work, I was able to expand my skill set to include backend development using agile methodology. We created a proof of concept for the company, Bio-Rad. I also learned how to analyze data in my internship at UCSF to determine the affects of artifacts when using wrist-worn sensors. ",
  aboutParaThree:
    "I'm very excited about learning new ways to create positive user experiences and expanding my knowledge base. I am currently looking for an opportunity to work with other highly motivated individuals. ",
  aboutImage: "https://srvs.us/julia/me.jpg",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      img: pythonIcon,
      para: " \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0python\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 "
    },
    {
      img: javaIcon,
      para: "java"
    },
    {
      img: bashIcon,
      para: "bash"
    },
    {
      img: awsIcon,
      para: "aws"
    },
    {
      img: basecampIcon,
      para: "basecamp"
    },
    {
      img: slackIcon,
      para: "slack"
    },
    {
      img: trelloIcon,
      para: "trello"
    },,
    {
      img: zoomIcon,
      para: "zoom"
    },,
    {
      img: microsoftIcon,
      para: "microsoft suite"
    },,
    {
      img: googleIcon,
      para: "google suite"
    },,
    {
      img: oathIcon,
      para: "Oauth"
    },,
    {
      img: githubIcon,
      para: "github"
    },,
    {
      img: htmlIcon,
      para: "html"
    },,
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  // promotionHeading: "",
  // promotionPara:
  //   "",
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's create your next experience together",
  social: [
    // Add Or Remove The Link Accordingly
    { img: githubIcon, url: "https://github.com/jlydick15" },
    {
      img: linkedinIcon,
      url: "https://www.linkedin.com/in/julia-c-lydick-000?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Bg13GI020Q6GEhnZ0Z7vlfQ%3D%3D",
    },
  ],

  // End Contact Section ---------------
}

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
